import { MatchClientModel } from '@sport1/types/sportData'

const getSportEventWinnerProp = ({
    homeScore = 0,
    homePenaltyScore = 0,
    awayScore = 0,
    awayPenaltyScore = 0,
    matchInfo,
    homeTeam,
}: MatchClientModel): 'HOME' | 'DRAW' | 'AWAY' => {
    if (matchInfo?.resultType === 'ABANDONED') {
        return 'DRAW'
    }
    if (matchInfo?.winnerTeamId && homeTeam) {
        return matchInfo?.winnerTeamId === homeTeam.id ? 'HOME' : 'AWAY'
    }
    if (homeScore + homePenaltyScore > awayScore + awayPenaltyScore) return 'HOME'
    if (homeScore + homePenaltyScore < awayScore + awayPenaltyScore) return 'AWAY'
    return 'DRAW'
}

export default getSportEventWinnerProp
