import { ResponsiveType } from '@sport1/news-styleguide/Types'

export const isAbove = (topPosition = 0): boolean => {
    return typeof window !== 'undefined' ? topPosition > window.innerHeight / 2 : false
}

export const getArrowPosition = (
    isAbove: boolean,
    matchHeight: number,
    activeHeadline: boolean
):
    | {
          top: ResponsiveType<number>
      }
    | {
          bottom: ResponsiveType<number>
      } => {
    return isAbove
        ? {
              bottom: matchHeight - (activeHeadline ? 58 : 34),
          }
        : {
              top: activeHeadline ? 40 : [16, 16, 16, 22],
          }
}
