import dayjs from 'dayjs'

type CalendarIcsEvent = {
    filename: string
    id: string
    title: string
    url: string
    startDatetime: string
    endDatetime?: string
    duration?: number // time in minutes
}

export const getIcsDate = (date?: string | number): string => {
    const t = date ? dayjs(date) : dayjs()
    const str = t.tz('UTC').format('YYYYMMDDTHHmmss')
    const Z = t.toString() !== 'Invalid Date' ? 'Z' : ''
    return `${str}${Z}`
}

export const downloadFile = (filename: string, content: string): void => {
    const ics = new Blob([content], { type: 'text/calendar' })
    // for Non-IE (chrome, firefox etc.)
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.style.display = 'none'
    const icsUrl = URL.createObjectURL(ics)
    a.href = icsUrl
    a.download = filename
    a.click()
    URL.revokeObjectURL(a.href)
    a.remove()
}

export const getEndDate = ({
    startDatetime,
    duration,
    endDatetime,
}: Pick<CalendarIcsEvent, 'startDatetime' | 'duration' | 'endDatetime'>): string => {
    if (endDatetime) {
        return getIcsDate(endDatetime)
    }
    if (duration) {
        const end = dayjs(startDatetime).add(duration, 'minutes')
        return getIcsDate(end.toISOString())
    }
    return getIcsDate(startDatetime)
}

export const downloadIcs = (icsData: CalendarIcsEvent): void => {
    const now = getIcsDate()

    const startDatetime = getIcsDate(icsData.startDatetime)
    const endDatetime = getEndDate(icsData)

    const filename = `${icsData.filename ?? `SPORT1_${now}`}.ics`

    // ics file time timezone is utc
    const icsContent = [
        `BEGIN:VCALENDAR`,
        `VERSION:2.0`,
        `PRODID:-//Sport1//DE`,
        `CALSCALE:GREGORIAN`,
        `METHOD:PUBLISH`,
        `BEGIN:VTIMEZONE`,
        `TZID:Europe/Berlin`,
        `TZURL:http://tzurl.org/zoneinfo-outlook/Europe/Berlin`,
        `END:VTIMEZONE`,
        `BEGIN:VEVENT`,
        `UID:${icsData.id}`,
        `DTSTART:${startDatetime}`,
        `DTEND:${endDatetime}`,
        `SUMMARY:${icsData.title}`,
        `CREATED:${now}`,
        `URL:${icsData.url}`,
        `UID:${icsData.id}`,
        `END:VEVENT`,
        `END:VCALENDAR`,
    ].join('\n')

    downloadFile(filename, icsContent)
}

export default downloadIcs
