import { FC } from 'react'
import { GenericComponentProps } from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { TeamStandingsClientModel } from '@sport1/types/sportData'
import Profile from '@sport1/news-styleguide/Profile'
import { PixelType, ResponsiveType, SpacingsVariants } from '@sport1/news-styleguide/Types'
import useFetch from '@/hooks/useFetch'
import Fallback from '@/components/Fallback'
import { getStandingsTitle } from '@/components/Standings/Title'
import { createTeamHref } from '@/utils/navigation/Navigator'

type TeamsOverviewProps = Pick<GenericComponentProps, 'contentUrl'> & {
    testID?: string
}

const SINGLE_SPACING = 16

const TeamsOverview: FC<TeamsOverviewProps> = ({ contentUrl, testID }) => {
    const { data, error } = useFetch<TeamStandingsClientModel>(contentUrl)
    const baseFallbackProps: Record<
        'paddingX' | 'paddingTop' | 'paddingBottom',
        keyof SpacingsVariants
    > = {
        paddingX: 'spacing-7',
        paddingTop: 'spacing-7',
        paddingBottom: 'spacing-4',
    }

    if (!error && !data) {
        return (
            <NonFlexingContainer
                backgroundColor="pearl"
                minHeight={'80vh' as PixelType}
                testID={`${testID}-no-data-filler`}
            />
        )
    }

    if (error) {
        return (
            <Fallback
                text="Beim Laden der Teams ist leider ein Fehler aufgetreten."
                {...baseFallbackProps}
                testID={`${testID}-fallback-text`}
            />
        )
    }

    if (!data?.standings?.length) {
        return (
            <Fallback
                text="Keine Teams verfügbar."
                {...baseFallbackProps}
                testID={`${testID}-fallback-text`}
            />
        )
    }

    return (
        <NonFlexingContainer
            testID={testID}
            horizontal
            flexWrap="wrap"
            width="100%"
            backgroundColor="pearl"
            paddingTop="spacing-7"
            paddingLeft="spacing-7"
            marginBottom="spacing-9"
        >
            {data.standings.map(({ teams, title }, index) =>
                teams?.length ? (
                    teams.map(({ team }) => (
                        <NonFlexingContainer
                            testID={`${testID}-team-${team?.name || 'unknown'}`}
                            key={`${team?.name || 'Unknown'}-${team?.id}`}
                            width={
                                [2, 2, 3, 4].map(
                                    numOfCards =>
                                        `calc((100% - ${numOfCards} * ${SINGLE_SPACING}px) / ${numOfCards})`
                                ) as ResponsiveType<PixelType>
                            }
                            borderRadius="radius-m"
                            border={1}
                            borderColor="moon-3"
                            marginBottom="spacing-7"
                            marginRight="spacing-7"
                        >
                            <Profile
                                testID={`${testID}-team-${team?.name || 'unknown'}-profile`}
                                type="TEAM"
                                title={team?.name || 'Unbekannt'}
                                imageProps={{ imageUrl: team?.imageUrl }}
                                profileHref={createTeamHref(team)}
                                labelProps={
                                    title
                                        ? {
                                              text: getStandingsTitle({ title, team }),
                                          }
                                        : undefined
                                }
                            />
                        </NonFlexingContainer>
                    ))
                ) : (
                    <Fallback
                        key={`no-teams-${index}`}
                        text="Keine Teams verfügbar."
                        {...baseFallbackProps}
                        testID={`${testID}-fallback-text`}
                    />
                )
            )}
        </NonFlexingContainer>
    )
}

export default TeamsOverview
