import { MatchClientModel } from '@sport1/types/sportData'

const KNOCKOUT_STAGES: MatchClientModel['roundType'][] = [
    'ROUND_OF_16',
    'ROUND_OF_32',
    'ROUND_OF_64',
    'ROUND_OF_128',
    'THIRD_PLACE',
    'FIFTH_PLACE',
    'SEVENTH_PLACE',
    'NINTH_PLACE',
    'ELEVENTH_PLACE',
    'THIRTEENTH_PLACE',
    'FIFTEENTH_PLACE',
    'SEVENTEENTH_PLACE',
    'NINETEENTH_PLACE',
    'TWENTY_FIRST_PLACE',
    'TWENTY_THIRD_PLACE',
    'TWENTY_FIFTH_PLACE',
    'TWENTY_SEVENTH_PLACE',
    'TWENTY_NINTH_PLACE',
    'THIRTY_FIRST_PLACE',
    'QUARTER_FINALS',
    'SEMI_FINALS',
    'FINAL',
    'CONFERENCE_SEMIFINAL',
    'CONFERENCE_FINAL',
    'NBA_FINALS',
    'SUPER_BOWL',
    'STANLEY_CUP_FINALS',
    'PLAY_OFFS',
    'QUALIFIER_ROUND',
    'EMPTY_PLAYOFF_ROUND_TYPE',
    'FIRST_ROUND',
    'SECOND_ROUND',
    'WILD_CARD',
    'DIVISIONAL_ROUND',
    'CONFERENCE_CHAMPIONSHIP',
    'WILD_CARD_SERIES',
    'DIVISION_SERIES',
    'LEAGUE_CHAMPIONSHIP_SERIES',
    'WORLD_SERIES',
]

const isKnockoutStage = (roundType?: MatchClientModel['roundType']): boolean =>
    KNOCKOUT_STAGES.includes(roundType)

export default isKnockoutStage
