import { useCallback } from 'react'
import { AdPlacementType } from '@sport1/types/web'
import { useMobile } from '@/hooks/useMobile'
import { useAdPlacementContext } from '@/context/AdPlacementContext/AdPlacementProvider'
import { getAdPlacement } from '@/utils/ads/display/createAdsPlacements'
import { getPlacementSuffix } from '@/context/AdPlacementContext/AdPlacementProvider.utils'
import { MappedAdPlacement } from '@/utils/ads/display/createAdsPlacements.types'

const useCalculateAdSlotPlacement = (): {
    calculateName: (adPlacement: AdPlacementType) => {
        id?: string
        placement?: MappedAdPlacement
        placementId?: string
    }
} => {
    const { placementCounter } = useAdPlacementContext()
    const { isMobile } = useMobile()
    const calculateName = useCallback(
        (adPlacement: AdPlacementType) => {
            const placement = getAdPlacement(adPlacement, 0, isMobile)
            if (!placement) return {}
            const placementWithSuffix = `${placement}${getPlacementSuffix(
                placementCounter[placement] + 1
            )}`
            return {
                id: placementWithSuffix,
                placement,
                placementId: placementWithSuffix,
            }
        },
        [isMobile, placementCounter]
    )
    return { calculateName }
}

export { useCalculateAdSlotPlacement }
