import { MatchClientModel } from '@sport1/types/sportData'
import { getTeamName } from '@/components/SportEvent/utils'

export const getPlayoffLabel = (match: MatchClientModel): string | undefined => {
    const { homePlayoffScore, homeTeam, awayPlayoffScore, awayTeam } = match
    if (typeof homePlayoffScore === 'number' && typeof awayPlayoffScore === 'number') {
        return `Playoffstand: ${getTeamName(
            homeTeam
        )} ${homePlayoffScore}:${awayPlayoffScore} ${getTeamName(awayTeam)}`
    }
}
