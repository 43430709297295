import React, { FC, memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { HeadlineProps } from '@sport1/news-styleguide/MatchCard'
import { MatchClientModel } from '@sport1/types/sportData'
import { LabelProps, TagProps } from '@sport1/types/web'
import { BetsServiceScope, MarketType, WidgetTrackingIdentifier } from '@sport1/bets-service-client'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { Spacing } from '@sport1/news-styleguide/LayoutTypes'
import { PixelType, ResponsiveType } from '@sport1/news-styleguide/Types'
import Event from './Event'
import Set from './Set'
import Match from './Match'
import getSportIdentifier from '@/utils/match/getSportIdentifier'
import isSetBasedSportEvent from '@/utils/match/isSetBasedSportEvent'
import isEventBasedSportEvent from '@/utils/match/isEventBasedSportEvent'
import { getMatchLivetickerHref } from '@/utils/navigation/Navigator'
import { useBetting } from '@/hooks/useBetting'
import { useMatchOverlay } from '@/utils/overlay/MatchOverlayProvider'
import useOddsRegistration from '@/hooks/useOddsRegistration'
import { BettingTrackingProps } from '@/hooks/useBettingTracking'
import TabbableArea from '@/components/TabbableArea'

export type SportEventProps = {
    scope?: BetsServiceScope
    marketType?: MarketType
    trackingCreation: WidgetTrackingIdentifier
    bettingGameURL?: string
    betsIgnoreMatchStatus?: boolean
    trackingInteractionLabel: string
    trackBetImpression?: (props: BettingTrackingProps) => void
    match: MatchClientModel
    tag?: TagProps | LabelProps
    headline?: HeadlineProps
    forceMobileView?: boolean
    minify?: boolean
    spaceY?: Spacing
    spaceTop?: Spacing
    spaceBottom?: Spacing
    trackCardClick?: () => void
    width?: ResponsiveType<number | PixelType>
    testID?: string
}

const SportEvent: FC<SportEventProps> = ({ match, tag, testID, ...props }) => {
    const {
        marketType,
        scope,
        trackingCreation,
        bettingGameURL,
        betsIgnoreMatchStatus,
        forceMobileView,
        trackingInteractionLabel,
        trackBetImpression: customTrackBetImpression,
        headline,
        trackCardClick,
        width,
    } = props

    const matchHref = useMemo(() => getMatchLivetickerHref({ match, tag }), [match, tag])
    const matchRef = useRef<HTMLDivElement>(null)
    const trackedBetImpression = useRef(false)
    const { toggleOverlay, isOverlayOpen, isMouseOver } = useMatchOverlay()
    const bettingProperties = useBetting({
        match,
        marketType,
        scope,
        trackingCreation,
        ignoreMatchStatus: betsIgnoreMatchStatus,
        disableImpressionTracking: !!trackedBetImpression,
    })
    useOddsRegistration({ matchId: match.id, odds: bettingProperties?.odds })

    const toggleMatchOverlay = useCallback(() => {
        if (matchRef?.current) {
            toggleOverlay(matchRef.current, {
                match,
                bettingGameURL,
                forceMobileView,
                marketType,
                scope,
                tag,
                trackingCreation,
                headline,
                trackingInteractionLabel,
            })
        }

        if (!isOverlayOpen) {
            trackCardClick?.()
        }
    }, [
        bettingGameURL,
        forceMobileView,
        headline,
        isOverlayOpen,
        marketType,
        match,
        scope,
        tag,
        toggleOverlay,
        trackCardClick,
        trackingCreation,
        trackingInteractionLabel,
    ])

    useEffect(() => {
        if (
            customTrackBetImpression &&
            !trackedBetImpression.current &&
            bettingProperties?.odds?.provider &&
            matchRef.current?.offsetWidth // we sometimes render without showing, eg for breakpoints
        ) {
            trackedBetImpression.current = true
            customTrackBetImpression({
                match,
                provider: bettingProperties?.odds?.provider,
                competition: bettingProperties.actionProperties?.competition,
            })
        }
    }, [bettingProperties, customTrackBetImpression, match])

    const content = useMemo(() => {
        const sportIdentifier = getSportIdentifier(match)
        if (isSetBasedSportEvent(sportIdentifier, match.matchInfo)) {
            return (
                <Set
                    testID={testID}
                    match={match}
                    onPressMore={toggleMatchOverlay}
                    matchHref={matchHref}
                    {...props}
                />
            )
        } else if (isEventBasedSportEvent(sportIdentifier) || match.sport?.id === 'hs:250') {
            return (
                <Event
                    testID={testID}
                    match={match}
                    onPressMore={toggleMatchOverlay}
                    matchHref={matchHref}
                    {...props}
                />
            )
        }
        return (
            <Match
                testID={testID}
                match={match}
                onPressMore={toggleMatchOverlay}
                matchHref={matchHref}
                bettingProperties={bettingProperties}
                {...props}
            />
        )
    }, [bettingProperties, match, matchHref, props, testID, toggleMatchOverlay])

    return (
        <TabbableArea
            testID="sport-event-tabbable-area"
            activateBlur={!isMouseOver && isOverlayOpen}
            onBlur={toggleMatchOverlay}
        >
            <NonFlexingContainer innerRef={matchRef} width={width}>
                {content}
            </NonFlexingContainer>
        </TabbableArea>
    )
}

export default memo(SportEvent)
