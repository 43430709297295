import dayjs, { Dayjs } from 'dayjs'

export const getTimeWithHourAndMinutes = (dateTime: string): string => {
    return dayjs(dateTime).format('HH:mm')
}

export const getDateFromZeroTimezoneStamp = (zeroTimezoneStamp: string): string => {
    return dayjs(zeroTimezoneStamp).format('DD.MM.YYYY')
}

export const getDateWithWeekday = (publishedAt: string): string => {
    return dayjs(publishedAt, 'YYYY-MM-DD').format('dddd, DD.MM.YYYY')
}

const dayJsFormat = 'YYYY-MM-DDTHH:mm:ss.000ZZ'

export const formatServerTimeToDayjs = (date: string): Dayjs => {
    return dayjs(date, dayJsFormat)
}

export const formatDayjsToServerTime = (date: Dayjs): string => {
    return date.format(dayJsFormat)
}

export const getDateString = (dayjs: Dayjs): string => {
    return dayjs.format('YYYY-MM-DD')
}
