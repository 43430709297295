import { MatchClientModel, TeamClientModel } from '@sport1/types/sportData'
import Types from '@sport1/news-styleguide/Types'
import { HeadlineProps } from '@sport1/news-styleguide/MatchCard'
import getSportIdentifier from '@/utils/match/getSportIdentifier'
import sportIdentifiersConfig from '@/utils/sportIdentifiers/SportIdentifiersConfig'

export type PostMatchProps = Omit<Types.PostMatchProps, 'onPressMatch'> | undefined
export type PreMatchProps = Omit<Types.PreMatchProps, 'onPressMatch'> | undefined
export type LiveMatchProps = Omit<Types.LiveMatchProps, 'onPressMatch'> | undefined

export enum MatchSheetViewType {
    PRE_MATCH,
    LIVE_MATCH,
    POST_MATCH,
}

export const getMatchResultProps = (
    match: MatchClientModel
): {
    homeScore: string
    awayScore: string
} => {
    if (typeof match.homeScore === 'number' && typeof match.awayScore === 'number') {
        return {
            homeScore: `${
                match.homeScore +
                (typeof match.homePenaltyScore === 'number' ? match.homePenaltyScore : 0)
            }`,
            awayScore: `${
                match.awayScore +
                (typeof match.awayPenaltyScore === 'number' ? match.awayPenaltyScore : 0)
            }`,
        }
    }
    return {
        homeScore: '-',
        awayScore: '-',
    }
}

export const getTeamName = (team?: TeamClientModel, showTeamFullName?: boolean): string => {
    if (showTeamFullName) {
        return team?.name || ''
    }
    return team?.shortName || team?.name || ''
}

export const getTeamImageUrl = ({
    team,
    sportIdentifier,
}: {
    team?: TeamClientModel
    sportIdentifier?: string
}): string | undefined => {
    if (!team) {
        return undefined
    }

    const { darts, tennis, motorsport, cycling } = sportIdentifiersConfig
    const flagTeams = [darts.name, tennis.name, motorsport.name, cycling.name]
    if (sportIdentifier && flagTeams.includes(sportIdentifier)) {
        return team.nationality?.imageUrl
    }

    return team.imageUrl
}

export type MatchUsageSource = 'default' | 'live-ticker'

export const getSportEventTeamProps = (
    match: MatchClientModel
):
    | {
          homeCompetitor: Types.CompetitorProps
          awayCompetitor: Types.CompetitorProps
      }
    | undefined => {
    const { homeTeam, awayTeam, competition, sport } = match
    const homeCompetitor = getTeamName(homeTeam)
    const awayCompetitor = getTeamName(awayTeam)
    if (homeCompetitor && awayCompetitor) {
        const sportIdentifier = getSportIdentifier({ competition, sport })
        return {
            homeCompetitor: {
                name: homeCompetitor,
                logoUrl:
                    getTeamImageUrl({
                        team: homeTeam,
                        sportIdentifier,
                    }) ?? '',
                resizeMode: 'contain',
            },
            awayCompetitor: {
                name: awayCompetitor,
                logoUrl:
                    getTeamImageUrl({
                        team: awayTeam,
                        sportIdentifier,
                    }) ?? '',
                resizeMode: 'contain',
            },
        }
    }
}

export const getMatchStatus = (match: MatchClientModel): MatchSheetViewType => {
    if (match.period === 'PRE_MATCH') return MatchSheetViewType.PRE_MATCH
    if (match.isLive) return MatchSheetViewType.LIVE_MATCH
    return MatchSheetViewType.POST_MATCH
}

export const getHeadlineProps = (
    match: MatchClientModel,
    onPressHeadline: () => void,
    headline?: string
): HeadlineProps | undefined => {
    if (headline && match.competition && match.competition.imageUrl) {
        return { text: headline, imageUrl: match.competition.imageUrl, onPressHeadline }
    }
    return undefined
}
