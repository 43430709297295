export enum MatchStatus {
    PRE_MATCH = 'PRE_MATCH',
    LIVE = 'LIVE',
    POST_MATCH = 'POST_MATCH',
}

const MatchStatusMap: Map<MatchStatus | undefined, string> = new Map([
    [MatchStatus.PRE_MATCH, 'pre-match'],
    [MatchStatus.LIVE, 'live-match'],
    [MatchStatus.POST_MATCH, 'post-match'],
])

export default MatchStatusMap
