import { useCallback } from 'react'
import { MatchClientModel } from '@sport1/types/sportData'
import { BettingActionProperties, WidgetTrackingIdentifier } from '@sport1/bets-service-client'
import { getMatchStatus } from '@/hooks/useBetting'
import { useTracking } from '@/utils/tracking/TrackingProvider'

export type BettingTrackingProps = Partial<
    Pick<BettingActionProperties, 'provider' | 'competition'>
> & {
    match?: MatchClientModel
}

type UseBettingTrackingReturnProps = {
    trackBetImpression: (props: BettingTrackingProps) => void
    trackBetClick: (props: BettingTrackingProps) => void
}

const useBettingTracking = (
    trackingCreation: WidgetTrackingIdentifier
): UseBettingTrackingReturnProps => {
    const { trackAdInteraction, trackAdView } = useTracking()

    const trackBetImpression = useCallback(
        ({ match, provider, competition }: BettingTrackingProps) => {
            trackAdView({
                ad_partner: provider || '',
                ad_placement: competition || match?.competition?.name || '',
                ad_type: trackingCreation,
                ad_status: match ? getMatchStatus(match) : '',
                ad_version: '1x2',
            })
        },
        [trackingCreation, trackAdView]
    )

    const trackBetClick = useCallback(
        ({ match, provider, competition }: BettingTrackingProps) => {
            trackAdInteraction({
                ad_partner: provider || '',
                ad_placement: competition || match?.competition?.name || '',
                ad_type: trackingCreation,
                ad_status: match ? getMatchStatus(match) : '',
                ad_version: '1x2',
            })
        },
        [trackingCreation, trackAdInteraction]
    )

    return {
        trackBetClick,
        trackBetImpression,
    }
}

export default useBettingTracking
