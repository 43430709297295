import React, { useCallback, useState } from 'react'
import { DropDownOption } from '@sport1/news-styleguide/Types'

export type StandingsWithDropdownKeys = 'firstValues' | 'secondValues'
export type StandingsWithDropdownValueType = DropDownOption<string>

type ValueType = Record<StandingsWithDropdownKeys, StandingsWithDropdownValueType[]>

export type UseStandingsValuesProps = {
    defaultValues: ValueType
}

const useStandingDropdownValues = ({ defaultValues }: UseStandingsValuesProps) => {
    const [dropdownValue, setDropdownValue] = React.useState<ValueType>(defaultValues)
    const [selectedDropdownValue, setSelectedDropdownValue] = useState<
        Record<StandingsWithDropdownKeys, StandingsWithDropdownValueType | undefined>
    >({ firstValues: undefined, secondValues: undefined })

    const selectValue = useCallback(
        (key: StandingsWithDropdownKeys, newValue: StandingsWithDropdownValueType) => {
            setSelectedDropdownValue(prevState => ({ ...prevState, [key]: newValue }))
        },
        []
    )

    const updateValues = useCallback(
        (key: StandingsWithDropdownKeys, newValues: StandingsWithDropdownValueType[]) => {
            setDropdownValue(prevState => ({ ...prevState, [key]: newValues }))
        },
        []
    )

    return {
        dropdownValue,
        selectedDropdownValue,
        selectValue,
        updateValues,
    }
}

export default useStandingDropdownValues
