import { MatchClientModel } from '@sport1/types/sportData'
import dayjs from 'dayjs'
import { getMatchResultProps, getTeamName } from '@/components/SportEvent/utils'

const getRelatedMatch = (match: MatchClientModel): string | undefined => {
    if (!match.relatedMatch) return undefined
    const { relatedMatch, scheduledStartTime } = match
    const { homeTeam, awayTeam, scheduledStartTime: relatedMatchTime } = relatedMatch
    const isFirstLeg = scheduledStartTime
        ? dayjs(relatedMatchTime).isBefore(scheduledStartTime)
        : true

    const matchScore =
        typeof relatedMatch.homeScore === 'number' && typeof relatedMatch.awayScore === 'number'
            ? getMatchResultProps(relatedMatch)
            : undefined
    const matchLabel = matchScore
        ? `${getTeamName(homeTeam)} ${matchScore.homeScore}:${matchScore.awayScore} ${getTeamName(
              awayTeam
          )}`
        : `${dayjs(relatedMatchTime).format('DD. MMMM YYYY - HH:mm')} Uhr`

    return `${isFirstLeg ? 'Hinspiel' : 'Rückspiel'}: ${matchLabel}`
}

export const getRelatedMatchLabel = (match: MatchClientModel): string | undefined => {
    const relatedMatchLabel = getRelatedMatch(match)
    if (relatedMatchLabel) {
        return relatedMatchLabel
    }
}
