import { SeasonClientModel } from '@sport1/types/sportData'

const getPreselectedStandingType = (
    contextId?: string,
    standingTypes?: SeasonClientModel['standingTypes']
): string | undefined => {
    let res: string | undefined = undefined
    if (contextId === '46' || contextId === '79') {
        res = standingTypes?.find(t => t === 'DIVISION')
    } else if (contextId === '61') {
        res = standingTypes?.find(t => t === 'CONFERENCE')
    }

    return res || standingTypes?.[0]
}

export default getPreselectedStandingType
