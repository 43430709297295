import React, { createContext, ReactElement, useCallback, useContext, useRef } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { SystemSpacingsProps } from '@sport1/news-styleguide/Types'
import MatchCardOverlay from '@/components/SportEvent/Overlay'
import { OddsDisclaimerContextProvider } from '@/utils/oddsDisclaimer/OddsDisclaimerProvider'
import { SportEventProps } from '@/components/SportEvent'
import { getArrowPosition, isAbove } from '@/helpers/matchOverlayHelper'

export type MatchOverlayState = {
    isOverlayOpen?: boolean
    isMouseOver: boolean
    toggleOverlay: (matchElement: HTMLDivElement, overlayData: OverlayProps) => void
    closeOverlay: () => void
}

const MatchOverlayContext = createContext<MatchOverlayState>({
    isMouseOver: false,
    toggleOverlay: () => void {},
    closeOverlay: () => void {},
})

interface MatchOverlayProviderProps extends SystemSpacingsProps {
    top?: number
    children?: ReactElement | ReactElement[]
}

type OverlayProps = Pick<
    SportEventProps,
    | 'bettingGameURL'
    | 'forceMobileView'
    | 'marketType'
    | 'match'
    | 'scope'
    | 'tag'
    | 'trackingCreation'
    | 'headline'
> & { trackingInteractionLabel: string }

export const MatchOverlayProvider = ({
    top = 0,
    paddingX,
    children,
}: MatchOverlayProviderProps): ReactElement => {
    const [overlayData, setOverlayData] = React.useState<OverlayProps>()
    const [isMouseOver, setIsMouseOver] = React.useState(false)
    const matchRef = useRef<HTMLDivElement>()
    const containerRef = useRef<HTMLDivElement>(null)

    const closeOverlay = useCallback(() => {
        matchRef.current = undefined
        setOverlayData(undefined)
    }, [matchRef])

    const toggleOverlay = useCallback(
        (matchElement: HTMLDivElement, data: OverlayProps) => {
            if (overlayData && overlayData.match.id === data.match.id) {
                closeOverlay()
            } else {
                matchRef.current = matchElement
                setOverlayData(data)
            }
        },
        [overlayData, closeOverlay]
    )

    const renderOverlay = useCallback(() => {
        const matchRect = matchRef?.current?.getBoundingClientRect()
        const containerRect = containerRef?.current?.getBoundingClientRect()
        const {
            bettingGameURL,
            marketType,
            match,
            scope,
            tag,
            trackingCreation,
            headline,
            trackingInteractionLabel,
        } = overlayData || {}

        if (
            typeof window === 'undefined' ||
            !containerRect ||
            !containerRef?.current ||
            !match ||
            !matchRect ||
            !matchRef?.current ||
            !trackingCreation ||
            !trackingInteractionLabel
        ) {
            return null
        }

        const isOverlayAbove = isAbove(matchRect.top)

        const spaceTop = isOverlayAbove ? undefined : matchRef.current.offsetTop + top

        const spaceLeft = matchRect.left - containerRect.left || 0
        const spaceBottom = isOverlayAbove
            ? containerRect.bottom - matchRect.top - matchRect.height
            : undefined

        return (
            <NonFlexingContainer
                bottom={spaceBottom}
                left={spaceLeft}
                onMouseLeave={(): void => setIsMouseOver(false)}
                onMouseOver={(): void => setIsMouseOver(true)}
                position="absolute"
                top={spaceTop}
                width={matchRect.width - 42}
            >
                <OddsDisclaimerContextProvider>
                    <MatchCardOverlay
                        paddingX={paddingX}
                        bettingGameURL={bettingGameURL}
                        marketType={marketType}
                        match={match}
                        scope={scope}
                        tag={tag}
                        trackingCreation={trackingCreation}
                        trackingInteractionLabel={trackingInteractionLabel}
                        {...getArrowPosition(isOverlayAbove, matchRect.height, !!headline)}
                    />
                </OddsDisclaimerContextProvider>
            </NonFlexingContainer>
        )
    }, [overlayData, paddingX, top])

    return (
        <MatchOverlayContext.Provider
            value={{
                isOverlayOpen: !!overlayData,
                toggleOverlay,
                closeOverlay,
                isMouseOver,
            }}
        >
            <NonFlexingContainer
                innerRef={containerRef}
                position="relative"
                testID="match-overlay-provider-container"
            >
                {children}
                {renderOverlay()}
            </NonFlexingContainer>
        </MatchOverlayContext.Provider>
    )
}

export const useMatchOverlay = (): MatchOverlayState =>
    useContext<MatchOverlayState>(MatchOverlayContext)
