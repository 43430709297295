import React, { FC, useMemo } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import DropDown from '@sport1/news-styleguide/DropDown'
import {
    DropDownOption,
    PixelType,
    ResponsiveType,
    SpacingsVariants,
} from '@sport1/news-styleguide/Types'
import Font from '@sport1/news-styleguide/Font'
import FlexingContainer from '@sport1/news-styleguide/FlexingContainer'
import { CompetitionClientModel, SeasonClientModel } from '@sport1/types/sportData'
import getPreselectedStandingType from './util/getPreselectedStandingType'
import useStandingDropdownValues, {
    UseStandingsValuesProps,
} from './util/useStandingDropdownValues'
import { StandingsWithDropdownTypes } from './types'
import Standings from '@/components/Standings'
import useDropdownTracking from '@/hooks/useDropdownTracking'
import { useTracking } from '@/utils/tracking/TrackingProvider'
import { useNavigation } from '@/utils/navigation/Standard'
import ActionButtonsFooter from '@/components/ActionButtonsFooter'
import Headline from '@/components/Headline'
import { useMediaQueryContext } from '@/utils/breakpoints/MediaQuery'
import { SizeTypes } from '@/types/size'
import { sport1Regular } from '@/helpers/fonts'

type Props = {
    contentUrl: string
    testID?: string
    originalDropdownData: (SeasonClientModel | CompetitionClientModel)[]
    dropdownOptions: UseStandingsValuesProps['defaultValues']
    headline?: string
    dropdownLabel: string
    componentType?: StandingsWithDropdownTypes
}

const lookupStandingType = (value: string) => {
    switch (value) {
        case 'DIVISION':
            return 'Division'
        case 'CONFERENCE':
            return 'Conference'
        case 'LEAGUE':
            return 'League'
    }
    return value
}

const toTypeOption = (value: string): DropDownOption<string> => ({
    label: lookupStandingType(value),
    value,
})

const StandingsWithDropdown: FC<Props> = ({
    testID,
    contentUrl,
    originalDropdownData,
    headline: altHeadline,
    dropdownLabel,
    dropdownOptions,
    componentType = 'TEAM',
}) => {
    const { dropdownValue, selectedDropdownValue, selectValue, updateValues } =
        useStandingDropdownValues({ defaultValues: dropdownOptions })
    const { currentDevice } = useMediaQueryContext()
    const trackDropdownChange = useDropdownTracking()
    const { trackInteraction } = useTracking()
    const { navigation } = useNavigation()

    const padding = ['spacing-7', 'spacing-9', 'spacing-9', 'spacing-10'] as ResponsiveType<
        keyof SpacingsVariants
    >
    const headline = useMemo(
        () =>
            altHeadline ||
            (navigation?.tag?.title ? `${navigation?.tag?.title} Tabelle` : 'Tabelle'),
        [altHeadline, navigation?.tag?.title]
    )

    React.useEffect(() => {
        selectValue('firstValues', dropdownValue.firstValues[0])
        const season = originalDropdownData.find(s => s.id === dropdownValue.firstValues[0]?.value)
        if (season && 'standingTypes' in season && season.standingTypes?.length) {
            updateValues('secondValues', season.standingTypes.map(toTypeOption))

            const preSelected = getPreselectedStandingType(
                navigation?.tag?.contextId,
                season.standingTypes
            )
            if (preSelected) {
                selectValue('secondValues', toTypeOption(preSelected))
            }
        }
    }, [
        navigation?.tag?.contextId,
        originalDropdownData,
        selectValue,
        updateValues,
        dropdownValue.firstValues,
    ])

    const onDropdownValueChange = React.useCallback(
        ({ season, standingType }: { season?: string; standingType?: string }) => {
            const type = standingType ?? selectedDropdownValue.secondValues?.value
            trackInteraction({
                interaction_category: 'widget',
                interaction_action: 'tabelle_impression',
                interaction_label: season ?? selectedDropdownValue.firstValues?.label,
                sportsdata_match: type ? lookupStandingType(type) : undefined,
            })
            trackDropdownChange()
        },
        [
            selectedDropdownValue.firstValues?.label,
            selectedDropdownValue.secondValues?.value,
            trackDropdownChange,
            trackInteraction,
        ]
    )

    const dataUrl = useMemo(() => {
        if (selectedDropdownValue.firstValues?.value) {
            return contentUrl
                .replace(
                    'competition/{competitionId}/season/{seasonId}',
                    selectedDropdownValue.firstValues.value
                )
                .replace('{seasonId}', selectedDropdownValue.firstValues.value)
                .replace('{standingType}', selectedDropdownValue.secondValues?.value ?? '')
        }
    }, [
        contentUrl,
        selectedDropdownValue.firstValues?.value,
        selectedDropdownValue.secondValues?.value,
    ])

    const onSeasonChange = React.useCallback(
        (option: DropDownOption<string>) => {
            const season = originalDropdownData.find(s => s.id === option.value)
            selectValue('firstValues', option)
            if (season && 'standingTypes' in season && season.standingTypes?.length) {
                updateValues('secondValues', season.standingTypes.map(toTypeOption))

                const preSelected = getPreselectedStandingType(
                    navigation?.tag?.contextId,
                    season.standingTypes
                )
                if (preSelected) {
                    selectValue('secondValues', toTypeOption(preSelected))
                }
            }

            onDropdownValueChange({ season: option.label })
        },
        [
            navigation?.tag?.contextId,
            onDropdownValueChange,
            originalDropdownData,
            selectValue,
            updateValues,
        ]
    )

    const onStandingTypeChange = React.useCallback(
        (option: DropDownOption<string>) => {
            selectValue('secondValues', option)
            onDropdownValueChange({ standingType: option.value })
        },
        [onDropdownValueChange, selectValue]
    )

    const dropDownWrapperWidth = dropdownValue.secondValues.length > 1 ? '50%' : '30%'
    return (
        <>
            <NonFlexingContainer
                backgroundColor="pearl"
                marginBottom={['spacing-7', 'spacing-7', 'spacing-7', 'spacing-9']}
                minHeight={'100svh' as PixelType}
                testID={`${testID}-standings-with-dropdown`}
            >
                <NonFlexingContainer
                    testID={`${testID}-headline-dropdown-wrapper`}
                    paddingX={padding}
                    paddingTop={componentType === 'TEAM' ? padding : 'spacing-none'}
                >
                    {componentType === 'TEAM' && (
                        <Headline
                            testID={`${testID}-headline`}
                            noStyle
                            component={{ text: headline }}
                        />
                    )}
                    <NonFlexingContainer
                        testID={`${testID}-dropdown-label-wrapper`}
                        marginY="spacing-6"
                    >
                        <Font
                            testID={`${testID}-label`}
                            fontSize="font-size-3"
                            lineHeight="24px"
                            color="keshi-3"
                            nextFontFamilyVariant={sport1Regular.style.fontFamily}
                        >
                            {dropdownLabel}
                        </Font>
                        <NonFlexingContainer
                            testID={`${testID}-dropdown-wrapper`}
                            horizontal={currentDevice !== SizeTypes.MOBILE}
                            width={['100%', dropDownWrapperWidth, dropDownWrapperWidth]}
                        >
                            <FlexingContainer>
                                <DropDown
                                    testID={`${testID}-dropdown`}
                                    options={dropdownValue.firstValues}
                                    onOptionChange={onSeasonChange}
                                    selectedOption={selectedDropdownValue.firstValues}
                                />
                            </FlexingContainer>
                            {dropdownValue.secondValues.length > 1 ? (
                                <FlexingContainer
                                    paddingLeft={['spacing-none', 'spacing-7', 'spacing-7']}
                                    paddingTop={['spacing-4', 'spacing-none', 'spacing-none']}
                                >
                                    <DropDown
                                        testID={`${testID}-type-dropdown`}
                                        options={dropdownValue.secondValues}
                                        onOptionChange={onStandingTypeChange}
                                        selectedOption={selectedDropdownValue.secondValues}
                                    />
                                </FlexingContainer>
                            ) : null}
                        </NonFlexingContainer>
                    </NonFlexingContainer>
                </NonFlexingContainer>
                {dataUrl ? (
                    <NonFlexingContainer
                        testID={`${testID}-standings-wrapper`}
                        paddingX={['spacing-none', 'spacing-9', 'spacing-9', 'spacing-10']}
                    >
                        <Standings contentUrl={dataUrl} />
                    </NonFlexingContainer>
                ) : null}
            </NonFlexingContainer>
            {componentType === 'TEAM' && (
                <ActionButtonsFooter
                    navigationItems={navigation?.tag?.navigationItems?.filter(
                        e => e.value !== 'web-standings-sport'
                    )}
                    title={navigation?.tag?.title ?? ''}
                />
            )}
        </>
    )
}

export default StandingsWithDropdown
