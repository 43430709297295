import { MatchClientModel } from '@sport1/types/sportData'

export type SportEventStatus = 'PRE' | 'LIVE' | 'POST' | 'SPECIFIED'

const getSportEventStatus = (match: MatchClientModel): SportEventStatus => {
    const resultType = match.matchInfo?.resultType ?? ''
    const specifiedResultTypes = ['ABANDONED', 'POSTPONED', 'DELAYED', 'WALKOVER', 'CANCELLED']
    if (match.period === 'PRE_MATCH' || match.sport?.id === 'hs:250') {
        return 'PRE'
    } else if (specifiedResultTypes.includes(resultType)) {
        return 'SPECIFIED'
    } else if (match.isLive) {
        return 'LIVE'
    } else if (!match.isLive) {
        return 'POST'
    }
    return 'PRE'
}

export default getSportEventStatus
