import { PlayerClientModel } from '@sport1/types/sportData'

const getPlayerName = (
    playerInfo: PlayerClientModel,
    fallbackFormat?: 'lastname' | 'firstname lastname' | 'lastname, firstname'
): string => {
    if (playerInfo.nickName) {
        return playerInfo.nickName
    } else if (
        (fallbackFormat === 'firstname lastname' || fallbackFormat === 'lastname, firstname') &&
        playerInfo.firstName &&
        playerInfo.lastName
    ) {
        return fallbackFormat === 'firstname lastname'
            ? `${playerInfo.firstName} ${playerInfo.lastName}`
            : `${playerInfo.lastName}, ${playerInfo.firstName}`
    }
    return playerInfo.lastName || playerInfo.firstName || 'Unbekannt'
}

export default getPlayerName
