import { SportIdentifierType } from '@/utils/sportIdentifiers/SportIdentifiersConfig'

export type ResultType =
    | 'NORMAL_RESULT'
    | 'AGGREGATE'
    | 'AWAY_GOALS'
    | 'PENALTY_SHOOTOUT'
    | 'AFTER_EXTRA_TIME'
    | 'AFTER_PENALTY'
    | 'GOLDEN_GOAL'
    | 'ABANDONED'
    | 'CANCELLED'
    | 'POSTPONED'
    | 'VOID'
    | 'RETIRED'
    | 'WALKOVER'
    | 'DELAYED'

export const resultTypeLabels: Record<SportIdentifierType, Partial<Record<ResultType, string>>> = {
    soccer: {
        AFTER_EXTRA_TIME: 'nach Verlängerung',
        PENALTY_SHOOTOUT: 'im Elfmeterschießen',
        AFTER_PENALTY: 'nach Elfmeterschießen',
    },
    cycling: {},
    wintersport: {},
    basketball: {
        AFTER_EXTRA_TIME: 'nach Overtime',
    },
    handball: {
        AFTER_EXTRA_TIME: 'nach Verlängerung',
        PENALTY_SHOOTOUT: 'im Siebenmeterwerfen',
        AFTER_PENALTY: 'nach Siebenmeterwerfen',
    },
    football: {},
    icehockey: {
        AFTER_EXTRA_TIME: 'nach Overtime',
        PENALTY_SHOOTOUT: 'im Penaltyschießen',
        AFTER_PENALTY: 'nach Penaltyschießen',
    },
    baseball: {
        AFTER_EXTRA_TIME: 'Extra Innings',
    },
    darts: {},
    tennis: {},
    rugby: {},
    us_sport: {},
    motorsport: {},
    american_football: {
        AFTER_EXTRA_TIME: 'nach Overtime',
    },
    field_hockey: {},
    indoor_volleyball: {},
}
