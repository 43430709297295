import { SportIdentifierType } from '../sportIdentifiers/SportIdentifiersConfig'
import { ResultType, resultTypeLabels } from './resultTypeLabels'
import { MatchUsageSource } from '@/components/SportEvent/utils'

type MatchInfoProps = {
    resultType?: string
    sportIdentifier?: string
    teamName?: string
    source?: MatchUsageSource
}

export const getMatchInfoProps = ({
    resultType,
    teamName,
    sportIdentifier,
    source = 'default',
}: MatchInfoProps): string | undefined => {
    switch (resultType) {
        case 'POSTPONED':
            return 'Verlegt'
        case 'ABANDONED':
            return source === 'default' ? 'Abgebr.' : 'Abgebrochen'
        case 'CANCELLED':
            return 'Abgesagt'
        case 'DELAYED':
            return source === 'default' ? 'Verspätet' : undefined
        case 'RETIRED':
            return teamName ? `Aufgegeben von ${teamName}` : 'Aufgegeben'
        case 'WALKOVER':
            return 'Walkover'
        default:
            return resultType && sportIdentifier
                ? resultTypeLabels[sportIdentifier as SportIdentifierType][resultType as ResultType]
                : undefined
    }
}

export default getMatchInfoProps
