import Types from '@sport1/news-styleguide/Types'
import { MatchClientModel } from '@sport1/types/sportData'
import {
    BetsServiceScope,
    OddUIProps,
    useOdds,
    OddsProps,
    UseOddsType,
    WidgetTrackingIdentifier,
    MarketType,
    BettingActionProperties,
    TrackingMatchStatus,
} from '@sport1/bets-service-client'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { handleBettingAction } from '@/helpers/bettingHelper'
import { MatchStatus } from '@/helpers/bettingHelper/MatchStatusMap'
import useBettingTracking from '@/hooks/useBettingTracking'

type UseBettingParameter = {
    readonly match?: MatchClientModel
    readonly marketType?: MarketType
    readonly scope?: BetsServiceScope
    readonly trackingCreation: WidgetTrackingIdentifier
    readonly ignoreMatchStatus?: boolean
    readonly disableImpressionTracking?: boolean
}

export type UseBettingType = {
    odds: UseOddsType
    uiProperties?: Types.BetProps
    actionProperties?: Partial<BettingActionProperties>
    onPressOdd: (index: number) => void
    onPressBetProvider: () => void
}

export type BetsRowConfigData = {
    readonly marketType?: MarketType
    readonly scope?: BetsServiceScope
    readonly trackingCreation: WidgetTrackingIdentifier
    readonly betsIgnoreMatchStatus: boolean
}

export enum MatchPeriod {
    PRE_MATCH = 'PRE_MATCH',
    POSTPONED = 'POSTPONED',
}

export const getMatchStatus = (match: MatchClientModel): TrackingMatchStatus => {
    if (match.period === MatchPeriod.PRE_MATCH) {
        return 'pre-match'
    }
    if (match.isLive) {
        return 'live-match'
    }
    return 'post-match'
}

function getOddsForMatchStatus(
    properties: UseOddsType,
    match: MatchClientModel,
    ignoreMatchStatus?: boolean
): OddUIProps[] {
    if (ignoreMatchStatus) {
        return properties.odds
    }
    if (match.isLive) {
        return properties.ordinaryAdvertisementForLiveOdds
    }
    if (match.period === MatchPeriod.PRE_MATCH || match.period === MatchPeriod.POSTPONED) {
        return properties.odds
    }
    return properties.ordinaryAdvertisement
}

export function createUIProperties(
    properties: UseOddsType,
    match: MatchClientModel,
    ignoreMatchStatus?: boolean
): Types.BetProps | undefined {
    if (properties.uiState === 'AVAILABLE') {
        return {
            odds: getOddsForMatchStatus(properties, match, ignoreMatchStatus),
            oddsProvider: properties.provider as Types.OddsProvider,
            onPressOdd: (index: number) => {
                properties.actionHandler('BET_QUOTES_AD_CLICK', { selectedEntry: index })
            },
            onPressProvider: () => {
                properties.actionHandler('BET_QUOTES_LOGO_CLICK')
            },
        }
    }
    return undefined
}

function getOddsProperties(
    parameter: UseBettingParameter
): Omit<OddsProps, 'bettingActionHandler'> | undefined {
    return parameter.trackingCreation &&
        parameter.scope &&
        parameter.match?.id &&
        parameter.match.competition?.id
        ? {
              eventId: parameter.match.id,
              competitionId: parameter.match.competition.id,
              marketType: parameter.marketType || 'RESULT_1x2',
              scope: parameter.scope,
              trackingData: {
                  trackingIdentifier: parameter.trackingCreation,
                  matchStatus: getMatchStatus(parameter.match),
              },
          }
        : undefined
}

function getImpressionHash(marketType: MarketType): string {
    return `${marketType}`
}

function getActionHandlerMatchStatus(match: MatchClientModel): MatchStatus {
    if (match.isLive) {
        return MatchStatus.LIVE
    } else if (match.period === MatchPeriod.PRE_MATCH || match.period === MatchPeriod.POSTPONED) {
        return MatchStatus.PRE_MATCH
    }
    return MatchStatus.POST_MATCH
}

export function useBetting(parameter: UseBettingParameter): UseBettingType | undefined {
    const oddsProperties = useMemo(() => getOddsProperties(parameter), [parameter])
    const impressionHash = useRef<string>('')
    const actionProperties = useMemo(() => ({}), [])
    const { trackBetImpression, trackBetClick } = useBettingTracking(parameter.trackingCreation)

    const bettingActionHandler = useCallback(
        (bettingActionProperties: BettingActionProperties) => {
            Object.assign(actionProperties, bettingActionProperties)
            handleBettingAction({
                bettingActionProperties,
                matchStatus: parameter.match && getActionHandlerMatchStatus(parameter.match),
                ignoreMatchStatus: parameter.ignoreMatchStatus,
                trackBetImpression,
                trackBetClick,
                match: parameter.match,
                disableImpressionTracking: parameter.disableImpressionTracking,
            })
        },
        [actionProperties, parameter, trackBetClick, trackBetImpression]
    )

    const odds = useOdds(
        oddsProperties
            ? {
                  ...oddsProperties,
                  bettingActionHandler,
              }
            : undefined
    )

    const uiProperties = useMemo(() => {
        return parameter.match
            ? createUIProperties(odds, parameter.match, parameter.ignoreMatchStatus)
            : undefined
    }, [odds, parameter.ignoreMatchStatus, parameter.match])

    useEffect(() => {
        if (odds.uiState === 'AVAILABLE') {
            const hash = getImpressionHash(parameter.marketType || 'RESULT_1x2')
            if (hash !== impressionHash.current) {
                impressionHash.current = hash
                odds.actionHandler('BET_QUOTES_IMPRESSION')
            }
        }
    }, [odds, parameter.marketType])

    const onPressOdd = useCallback(
        (index: number) => {
            uiProperties?.onPressOdd?.(index)
        },
        [uiProperties]
    )

    const onPressBetProvider = useCallback(() => {
        uiProperties?.onPressProvider?.()
    }, [uiProperties])

    return uiProperties
        ? {
              actionProperties,
              odds,
              uiProperties,
              onPressOdd,
              onPressBetProvider,
          }
        : {
              odds,
              onPressOdd,
              onPressBetProvider,
          }
}
