import { useState, useEffect, useCallback } from 'react'
import {
    BREAKPOINT_SCREEN_SMALL,
    BREAKPOINT_SCREEN_MEDIUM,
    BREAKPOINT_SCREEN_LARGE,
} from '@/helpers/pageHelper'

export enum ScreenType {
    MOBILE,
    TABLET,
    LAPTOP,
    DESKTOP,
}

const getScreenType = (windowWidth: number): ScreenType | undefined => {
    if (windowWidth < BREAKPOINT_SCREEN_SMALL) {
        return ScreenType.MOBILE
    } else if (windowWidth < BREAKPOINT_SCREEN_MEDIUM) {
        return ScreenType.TABLET
    } else if (windowWidth < BREAKPOINT_SCREEN_LARGE) {
        return ScreenType.LAPTOP
    }
    return ScreenType.DESKTOP
}

type ReturnValue = {
    screenType: ScreenType | undefined
    isMobileDevice: () => boolean
}

const useScreenType = (): ReturnValue => {
    const isClient = typeof window === 'object'

    // unable to test server-side branch
    /* istanbul ignore next */
    const getWidth = useCallback((): number => (isClient ? window.innerWidth : -1), [isClient])

    const [windowWidth, setWindowWidth] = useState(getWidth())
    const [screenType, setScreenType] = useState<ScreenType | undefined>(getScreenType(windowWidth))

    useEffect(() => {
        // unable to test server-side branch
        /* istanbul ignore next */
        if (!isClient) {
            return
        }

        const handleResize = (): void => {
            setWindowWidth(getWidth())
        }

        window.addEventListener('resize', handleResize)
        return (): void => window.removeEventListener('resize', handleResize)
    }, [getWidth, isClient])

    useEffect(() => {
        setScreenType(getScreenType(windowWidth))
    }, [windowWidth])

    const isMobileDevice = (): boolean => screenType === ScreenType.MOBILE

    return { screenType, isMobileDevice }
}

export default useScreenType
