import sportIdentifiers from '@/utils/sportIdentifiers/SportIdentifiersConfig'

const EVENT_BASED_SPORT_EVENTS = [
    sportIdentifiers.cycling.name,
    sportIdentifiers.motorsport.name,
    sportIdentifiers.wintersport.name,
]

const isEventBasedSportEvent = (sportIdentifier?: string): boolean =>
    !!(sportIdentifier && EVENT_BASED_SPORT_EVENTS.includes(sportIdentifier))

export default isEventBasedSportEvent
