import React from 'react'
import { UseOddsType } from '@sport1/bets-service-client'
import { useOddsDisclaimerContext } from '@/utils/oddsDisclaimer/OddsDisclaimerProvider'

type OddsRegistrationProps = {
    matchId?: string
    odds?: UseOddsType
}

const useOddsRegistration = ({ matchId, odds }: OddsRegistrationProps): void => {
    const { registerMatch, unregisterMatch } = useOddsDisclaimerContext()

    React.useEffect(() => {
        registerMatch({
            matchId,
            uiState: odds?.uiState,
            needsRevenueDisclaimer: odds?.needsRevenueDisclaimer,
            provider: odds?.provider,
        })
        return () => {
            if (matchId) {
                unregisterMatch(matchId)
            }
        }
    }, [registerMatch, unregisterMatch, matchId, odds])
}

export default useOddsRegistration
