import { WidgetTrackingIdentifier } from '@sport1/bets-service-client'
import { useRouter } from 'next/router'

type Hook = {
    trackingCreation: WidgetTrackingIdentifier
}

const useTrackingCreation = (base: string): Hook => {
    const { pathname } = useRouter()
    let trackingCreation = base

    if (pathname.includes('channel')) {
        trackingCreation = `${base}-channel`
    }
    if (pathname === '/') {
        trackingCreation = `${base}-home`
    }

    return { trackingCreation: trackingCreation as WidgetTrackingIdentifier }
}

export default useTrackingCreation
