import { BettingActionProperties } from '@sport1/bets-service-client'
import { MatchClientModel } from '@sport1/types/sportData'
import { MatchStatus } from '@/helpers/bettingHelper/MatchStatusMap'
import { BettingTrackingProps } from '@/hooks/useBettingTracking'

type BettingActionProps = {
    bettingActionProperties: BettingActionProperties
    matchStatus?: MatchStatus
    ignoreMatchStatus?: boolean
    trackBetImpression: (props: BettingTrackingProps) => void
    trackBetClick: (props: BettingTrackingProps) => void
    match?: MatchClientModel
    disableImpressionTracking?: boolean
}

const openLink = (link: string): void => {
    window.open(link, '_blank')
}

// TODO: create a hook to directly use useBettingTracking hook
export const handleBettingAction = ({
    bettingActionProperties,
    matchStatus,
    ignoreMatchStatus,
    trackBetImpression,
    trackBetClick,
    match,
    disableImpressionTracking,
}: BettingActionProps): void => {
    const { targetLink, targetLinks, type, provider, competition } = bettingActionProperties

    switch (type) {
        case 'FAN_VOTING_IMPRESSION':
        case 'BET_QUOTES_IMPRESSION':
        case 'FAN_VOTING_ON_CARD_CLICK':
            !disableImpressionTracking && trackBetImpression({ match, provider, competition })
            break

        case 'BET_QUOTES_LOGO_CLICK':
        case 'BET_QUOTES_AD_CLICK':
            trackBetClick({ match, provider, competition })
            break
    }

    if (type === 'BET_QUOTES_LOGO_CLICK' && targetLink) {
        openLink(targetLink)
    } else if (
        ['BET_QUOTES_AD_CLICK', 'FAN_VOTING_ON_ACTION_BUTTON_CLICK'].includes(type) &&
        (targetLink || targetLinks)
    ) {
        let link = undefined
        if (targetLinks) {
            if (ignoreMatchStatus) {
                link = targetLinks.preMatch
            } else {
                switch (matchStatus) {
                    case MatchStatus.PRE_MATCH:
                        link = targetLinks.preMatch
                        break
                    case MatchStatus.LIVE:
                        link = targetLinks.live
                        break
                    case MatchStatus.POST_MATCH:
                        link = targetLinks.postMatch
                        break
                    default:
                        link = targetLinks.preMatch
                        break
                }
            }
        } else if (targetLink) {
            link = targetLink
        }
        if (link) {
            openLink(link)
        }
    }
}
