import { MatchInfoBaseClientModel } from '@sport1/types/sportData'
import sportIdentifiers from '@/utils/sportIdentifiers/SportIdentifiersConfig'

const SET_BASED_SPORT_EVENTS = [
    sportIdentifiers.tennis.name,
    sportIdentifiers.indoor_volleyball.name,
]

const MIXED_BASED_SPORT_EVENTS = [sportIdentifiers.darts.name]

const isSetBasedSportEvent = (
    sportIdentifier?: string,
    matchInfo?: MatchInfoBaseClientModel
): boolean =>
    !!(sportIdentifier && SET_BASED_SPORT_EVENTS.includes(sportIdentifier)) ||
    (matchInfo?.bestOfSets && sportIdentifier && MIXED_BASED_SPORT_EVENTS.includes(sportIdentifier)
        ? matchInfo.bestOfSets > 1
        : false)

export default isSetBasedSportEvent
