import React, { memo, VFC } from 'react'
import LibInfoList from '@sport1/news-styleguide/InfoList'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { GenericComponentProps, GenericTeamInfoModel as TeamInfoProps } from '@sport1/types/web'

type InfoListProps = Pick<
    TeamInfoProps & GenericComponentProps,
    'additionalData' | 'componentKey'
> & { testID?: string }

const TeamInfo: VFC<InfoListProps> = ({ additionalData, testID }) => {
    testID === undefined ? (testID = 'team-info') : testID
    return (
        <NonFlexingContainer marginBottom="spacing-9" testID={`${testID}-wrapper`}>
            <NonFlexingContainer
                width="100%"
                backgroundColor="pearl"
                paddingTop={['spacing-7', 'spacing-10', 'spacing-10']}
                paddingBottom={['spacing-6', 'spacing-9', 'spacing-9']}
                paddingX={['spacing-7', 'spacing-9', 'spacing-10']}
                testID={`${testID}-inner-wrapper`}
            >
                <LibInfoList items={additionalData} headline="INFOS" testID={`${testID}-list`} />
            </NonFlexingContainer>
        </NonFlexingContainer>
    )
}

export default memo(TeamInfo)
